<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(save)">
      <h4 class="mb-6">{{ title }}</h4>

      <!-- DESCRIPCION DEL CANAL -->
      <validation-provider
        v-slot="{ errors }"
        :name="text.descriptionField.name"
        rules="required"
      >
        <v-text-field
          :label="text.descriptionField.text"
          v-model="form.description"
          :error-messages="errors"
          outlined
        ></v-text-field>
      </validation-provider>

      <!-- CODIGO DEL CANAL -->
      <validation-provider
        v-slot="{ errors }"
        :name="text.codeField.name"
        rules="required"
      >
        <v-text-field
          :label="text.codeField.text"
          v-model="form.code"
          :error-messages="errors"
          outlined
        ></v-text-field>
      </validation-provider>

      <v-divider class="my-3"></v-divider>

      <!-- DEPENDENCIA DEL CANAL -->
      <v-autocomplete
        :disabled="!!formData"
        :items="channels"
        v-model="form.id"
        :label="text.idField.text"
        item-text="description"
        item-value="id"
        :hint="text.idField.hint"
        outlined
        persistent-hint
        clearable
      ></v-autocomplete>

      <div class="mt-3 text-right">
        <v-tooltip top v-if="deleteCondition && !!formData">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab text x-small color="error" v-on="on" v-bind="attrs">
              <v-icon small>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>El canal no se puede eliminar, tiene subcanales activos.</span>
        </v-tooltip>

        <v-btn
          class="mr-2"
          color="error"
          @click="remove()"
          :disabled="deleteCondition"
          :loading="deleteLoading"
          text
          v-if="!!formData"
          >Eliminar</v-btn
        >
        <v-btn
          color="primary"
          type="submit"
          :disabled="invalid || deleteLoading"
          :loading="loading"
          text
          >{{ !!formData ? "Actualizar" : "Guardar" }}</v-btn
        >
      </div>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: "ChannelForm",
  data: () => ({
    channelObject: undefined,
    form: {
      id: null,
      description: "",
      code: "",
    },
  }),
  props: {
    formData: {
      type: Object,
      default: null,
      description: "Data del formulario cuando se actualiza un canal",
    },
    channels: {
      type: Array,
      default: () => [],
      description: "Listado de todos los canales",
    },
    selectedChannel: {
      type: String,
      default: "",
      description: "Canal el cual se encuentra seleccionado en ese momento",
    },
    title: {
      type: String,
      default: "",
      description: "Titulo del formulario",
    },
    loading: {
      type: Boolean,
      default: false,
      description: "True cuando el formulario se este procesando",
    },
    deleteLoading: {
      type: Boolean,
      default: false,
      description:
        "True cuando el canal se encuentra en proceso de eliminacion",
    },
  },
  created() {
    if (this.selectedChannel !== "") {
      this.form.id = this.selectedChannel;
    }
    if (this.formData) {
      this.setup();
    }
  },
  methods: {
    save() {
      this.$emit("save", this.form);
    },
    remove() {
      this.$emit("remove", this.channelObject?.id);
    },
    setup() {
      this.channelObject = this.channels.find((e) => e.id == this.formData._id);
      this.form.id = this.formData.id_padre || "";
      this.form.code = this.formData.codigo || "";
      this.form.description = this.formData.canal_descripcion || "";
    },
  },
  computed: {
    text() {
      return {
        descriptionField: {
          name: "Descripción",
          text: "Descripción del Canal",
        },
        codeField: {
          name: "Código",
          text: "Código del Canal",
        },
        idField: {
          name: "Dependencia",
          text: "Dependencia del Canal (opcional)",
          hint: "Selecciona sí este canal pertenece de otro canal o sucursal",
        },
      };
    },
    deleteCondition() {
      return (
        this.loading ||
        typeof this.channelObject?.hasDependencies == "undefined" ||
        this.channelObject?.hasDependencies
      );
    },
  },
};
</script>
